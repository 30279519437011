import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useIntl } from "react-intl";
import Container from "../components/container";
import { Heading, Text, List } from "../components/typo.js";

const TermsOfUse = (pageContext) => {
  const intl = useIntl();
  const locale = pageContext.pageContext.locale;
  const originalPath = pageContext.pageContext.originalPath;

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Seo title={intl.formatMessage({ id: "page-privacy-policy" })} />
      <Container>
        <Heading level="1" textAlign="center">
          {intl.formatMessage({ id: "page-privacy-policy" })}
        </Heading>
        <Text strong>
          ABOUT EXPERIENCE TIME - THE DUBAI EDITION AND THIS PRIVACY STATEMENT
        </Text>
        <Text>
          The ‘The Experience Time - The Dubai Edition’ is an opportunity to
          enjoy the precision of beautiful timepieces, learn more about next
          generation watchmaking materials, meet our artisans, forget about
          time, live in the moment and dream (the “Event”). The website found at
          www.experience-time.com (“Website”) and the Event is run on behalf of
          the following Maisons:
        </Text>
        <Text>
          <List>
            <li>A. Lange & Söhne</li>
            <li>Baume & Mercier</li>
            <li>IWC Schaffhausen</li>
            <li>Jaeger-LeCoultre</li>
            <li>Montblanc</li>
            <li>Piaget</li>
            <li>Roger Dubuis</li>
            <li>Vacheron Constantin</li>
          </List>
        </Text>
        <Text>
          Any personal information which you provide through the Website is
          collected, used and disclosed in accordance with each individual
          Maison’s privacy policy. For more details on how each Maison handles
          your information please refer to the section titled ‘Maison Privacy
          Policies’ below.
        </Text>
        <Text strong>USE OF COOKIES ON THE WEBSITE</Text>
        <Text>
          Like most websites, the Website logs IP addresses and uses cookies and
          similar technologies that allow you to be recognised and to customise
          your experience, as well as to provide the Maisons with information
          about the way visitors access the Website.
        </Text>
        <Text strong>WHAT IS A COOKIE ?</Text>
        <Text>
          Cookies are text files, containing small amounts of information, which
          are downloaded to your computer or mobile device when you visit a
          website or mobile application. They are used to recognise your
          computer as you move between pages on a website, or when you return to
          a website or mobile application you have visited previously. Cookies
          are widely used in order to make platforms work, or to work more
          efficiently, as well as to provide information to the owners of the
          platform.
        </Text>
        <Text>
          Cookies are used on the Website to enhance your online experience (for
          example, by remembering your language and other preferences) and to
          better understand how the Website is used, for example, whether you
          have visited the Website before or whether you are a new visitor.
          There are two broad categories of cookies :
        </Text>
        <Text>
          <List>
            <li>
              First party cookies, served directly to your computer or mobile
              device. They are used to recognise your computer or mobile device
              when it revisits the Website.
            </li>
            <li>
              Third party cookies, which are served by a third party service
              provider on the Website, and can be used by the service provider
              to recognise your computer or mobile device when it visits other
              platforms. Third party cookies are most commonly used for platform
              analytics or advertising purposes.
            </li>
          </List>
        </Text>
        <Text>
          Cookies can remain on your computer or mobile device for different
          periods of time. Both 'session cookies' and ‘permanent cookies’ are
          used on the Website. Session cookies exist only while your browser is
          open and are deleted automatically once you close your browser.
          Permanent cookies survive after your browser is closed, and can be
          used to recognise your computer or mobile device when you open your
          browser and browse the internet again.
        </Text>
        <Text strong>WHAT COOKIES DOES THE WEBSITE USE ?</Text>
        <Text>
          The Website serves only the following types of cookies to your
          computer or mobile device:
        </Text>
        <Text>
          <span style={{ fontWeight: "600" }}>
            Cookies necessary for essential purposes:
          </span>
          These cookies are essential to provide you with services available
          through the Website and to use some of its features, such as access to
          secure areas. Without these cookies, services you have asked for, like
          secure login accounts, would not be possible.
        </Text>
        <Text>
          <span style={{ fontWeight: "600" }}>Functional Cookies:</span>
          These cookies serve a variety of purposes related to the presentation,
          performance and functionality of the Website. Their overall purpose,
          however, is to enhance visitors’ experience and enjoyment of the
          Website. For example, some of these cookies enable visitors to specify
          language, product or other Website preferences.
        </Text>
        <Text>
          <span style={{ fontWeight: "600" }}>Performance Cookies:</span>
          These cookies are used to collect information about how visitors use
          the Website. The information gathered does not identify any individual
          visitor and is aggregated. It includes the number of visitors to the
          Website and the pages that were visited on the Website. This
          information is used to help run the Website more efficiently, to
          gather broad demographic information and to monitor the level of
          activity on the Website.
        </Text>
        <Text strong>HOW TO CONTROL OR DELETE COOKIES?</Text>
        <Text>
          Non-essential cookies are used on the Website on the basis of your
          consent. You have the right withdraw that consent and refuse the use
          of cookies at any time and you can exercise this right by following
          the steps set out below. However, please note that if you choose to
          refuse cookies you may not be able to use the full functionality of
          the Website.
        </Text>
        <Text>
          You can set your cookie preferences by changing your browser settings
          so that cookies from the Website cannot be placed on your computer or
          mobile device. In order to do this, follow the instructions provided
          by your browser (usually located within the “Help”, “Tools” or “Edit”
          facility).
        </Text>
        <Text>
          Further information about cookies, including how to see what cookies
          have been set on your computer or mobile device and how to manage and
          delete them, visit{" "}
          <a
            href="https://www.allaboutcookies.org"
            target="_blank"
            rel="noreferrer"
          >
            www.allaboutcookies.org
          </a>{" "}
          and{" "}
          <a
            href="https://www.youronlinechoices.eu"
            target="_blank"
            rel="noreferrer"
          >
            www.youronlinechoices.eu
          </a>
          .
        </Text>
        <Text strong>IP ADDRESSES</Text>
        <Text>
          Information about your computer or mobile device will be collected,
          including where available your IP address, operating system, log-in
          times and browser type. This information is used to better understand
          how visitors use the Website and for internal reporting purposes. This
          information is anonymised and will be shared with advertisers,
          sponsors or other businesses.
        </Text>
        <Text strong>MAISON PRIVACY POLICIES</Text>
        <Text>
          If you have any questions or comments about this privacy statement, or
          privacy matters generally, please contact the relevant Maison using
          the contact details provided in its privacy policy, provided below.
          You can also use the Maison contact details if you wish to request
          access to the personal information each Maison holds about you or to
          unsubscribe from any further e-mail marketing communications.
        </Text>
        <Text>
          <List>
            <li>
              A. Lange & Söhne -{" "}
              <a
                href="https://www.alange-soehne.com/en/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                https://www.alange-soehne.com/en/privacy-policy
              </a>
            </li>
            <li>
              Baume & Mercier -{" "}
              <a
                href="https://www.baume-et-mercier.com/en/legal/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.baume-et-mercier.com/en/legal/privacy-policy.html
              </a>
            </li>
            <li>
              IWC Schaffhausen -{" "}
              <a
                href="https://www.iwc.com/en/terms-and-legal/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.iwc.com/en/terms-and-legal/privacy-policy.html
              </a>
            </li>
            <li>
              Jaeger-LeCoultre -{" "}
              <a
                href="https://www.jaeger-lecoultre.com/ae-en/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                https://www.jaeger-lecoultre.com/ae-en/privacy-policy
              </a>
            </li>
            <li>
              Montblanc -{" "}
              <a
                href="https://www.montblanc.com/en-ae/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.montblanc.com/en-ae/privacy-policy.html
              </a>
            </li>
            <li>
              Piaget -{" "}
              <a
                href="https://www.piaget.com/ae-en/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                https://www.piaget.com/ae-en/legal/privacy-policy
              </a>
            </li>
            <li>
              Roger Dubuis -{" "}
              <a
                href="https://www.rogerdubuis.com/en/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://www.rogerdubuis.com/en/privacy
              </a>
            </li>
            <li>
              Vacheron Constantin -{" "}
              <a
                href="https://www.vacheron-constantin.com/en/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.vacheron-constantin.com/en/privacy-policy.html
              </a>
            </li>
          </List>
        </Text>
        <Text>Last Updated: November 2022</Text>
      </Container>
    </Layout>
  );
};

export default TermsOfUse;
